import React, { useEffect } from "react";
import Background from "../Component/Background/Background";
import Button from "../Component/Button/Button";
import PasswordTextfield from "../Component/TextField/PasswordTextfield";
import styles from "./ForgotPassword.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { checkToken, forgotPassword } from "../API/forgot_password";
import { Circles } from "react-loader-spinner";

function ForgotPassword() {
  const [isButtonLoading, setButtonLoader] = React.useState(true);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  const [shackPassword, setShackPassword] = React.useState("");
  const [shackConfirmPassword, setShackConfirmPassword] = React.useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const passwordHandler = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const confirmPasswordHandler = (e) => {
    e.preventDefault();
    setconfirmPassword(e.target.value);
  };

  async function checkTokenApi(token) {
    const data = await checkToken(token);

    if (data) {
      if (data.data["s"] == 0) {
        navigate("/link-expired");
      }
    }
    setButtonLoader(false);
  }

  useEffect(() => {
    checkTokenApi(token);
  }, []);

  const onClick = async () => {
    if (!password) {
      setShackPassword("shack");
      setTimeout(() => setShackPassword(""), 700);
    } else if (password.length < 8) {
      alert("Password must be at least 8 characters");
    }
    else if (!confirmPassword) {
      setShackConfirmPassword("shack");
      setTimeout(() => setShackConfirmPassword(""), 700);
    }
    else if (confirmPassword.length < 8) {
      alert("Confirm password must be at least 8 characters");
    }
    else if (password !== confirmPassword) {
      setShackPassword("shack");
      setShackConfirmPassword("shack");
      setTimeout(() => {
        setShackConfirmPassword("");
        setShackPassword("");
      }, 700);
    } else {
      setButtonLoader(true);
      const data = await forgotPassword(password, token);
      setButtonLoader(false);
      if (data) {
        if (data.data["s"] == 1) {
          navigate("/changed-password");
        } else {
          navigate("/link-expired");
        }
      }
    }
  };
  return (
    <Background>
      <div className={styles.forgotPassword}>
        {/* logo app */}
        <div style={{width:"150px"}}>
          <img src="logo.png" style={{width:"100%"}}/>
        </div>

        {/* title */}
        <div className={styles.titleContainer}>
          <h1>Change Password ?</h1>
        </div>

        {/* form */}
        <PasswordTextfield
          lable="Password"
          onChange={passwordHandler}
          value={password}
          shack={shackPassword}
        />
        <PasswordTextfield
          lable="Confirm Password"
          onChange={confirmPasswordHandler}
          value={confirmPassword}
          shack={shackConfirmPassword}
        />

        {isButtonLoading ? (
          <div className={styles.circleCenter}>
            <Circles
            height="30"
            width="30"
            radius="9"
            color="black"
            ariaLabel="three-dots-loading"
            wrapperStyle
            wrapperClass
            
          />
          </div>
        ) : (
          <Button onClick={onClick}>
            <span>Submit</span>
          </Button>
        )}
      </div>
    </Background>
  );
}

export default ForgotPassword;
