

import React from "react";
import styles from "./ForgotPassword.module.css";
function ChangedPassword() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <img className={styles.logo} src="./logo.png" />

      <h4>Password has been successfully changed</h4>
    </div>
  );
}

export default ChangedPassword;
